import { Component, ViewChild } from '@angular/core';
import { FieldType } from '@ngx-formly/material';
import { MatInput } from '@angular/material/input';

@Component({
  selector: 'app-bootstrap-datepicker',
  template: `
  <div class="input-group">
    <input
      class="form-control"
      placement="bottom"
      ngbDatepicker
      #d="ngbDatepicker"
      [formControl]="formControl"
      [formlyAttributes]="field"
      placeholder="YYYY-MM-DD"
      [class.is-invalid]="showError">
      <div class="input-group-append">
        <button class="btn btn-outline-secondary calendar" (click)="d.toggle()" type="button"></button>
      </div>
    </div>
    `,
    styleUrls: ['./bootstrap-datepicker.component.scss']
})
export class BootstrapDatepickerComponent extends FieldType {
  // @ViewChild(MatInput) formFieldControl: MatInput;
}
