import {AfterViewInit, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {FieldType} from '@ngx-formly/core';
import { pick } from 'lodash';

@Component({
  selector: 'app-bootstrap-address',
  templateUrl: './bootstrap-address.component.html',
  styleUrls: ['./bootstrap-address.component.scss']
})
export class BootstrapAddressComponent extends FieldType implements OnInit, AfterViewInit {
  placesAutocomplete: any;

  @ViewChild('placesInput') placesInput: ElementRef;

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.placesAutocomplete = (window as any).places({
      appId: 'plAJFW2O3TZP',
      apiKey: 'b822d224b0d3c4839cd072f91eadb024',
      container: this.placesInput.nativeElement,
    });

    this.placesAutocomplete.on('change', e => {
      const addressValue = pick(e.suggestion, ['name', 'fullAddress', 'county', 'country', 'countryCode', 'type', 'latlng', 'postcode']);
      addressValue.location = {
        type: 'Point',
        coordinates: [e.suggestion.latlng.lng, e.suggestion.latlng.lat]
      };

      this.formControl.setValue(addressValue);
    });
  }
}
