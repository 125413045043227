import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormlyFormOptions } from '@ngx-formly/core';
import { HttpClient } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { of } from 'rxjs';
import { Router } from '@angular/router';
import { getForm } from '../../assets/test';

@Component({
  selector: 'app-form-bootstrap',
  templateUrl: './form-bootstrap.component.html',
  styleUrls: ['./form-bootstrap.component.scss']
})
export class FormBootstrapComponent implements OnInit {
  @Input() formIdentifier: string;
  form: FormGroup = new FormGroup({});
  model = { };
  title: string;
  description: string;
  url: string;
  fields: any;

  options: FormlyFormOptions = {
    formState: {
      awesomeIsForced: false,
    },
  };

  constructor(
    private http: HttpClient,
    private router: Router,
  ) { }

  async ngOnInit(): Promise<void> {
    await this.loadForm();
  }

  async loadForm() {
    const form = getForm() as any;
    this.fields = form.form;

    this.title = form.title ? form.title : null;
    this.description = form.description ? form.description : null;
    this.url = '/assets/sl-brand.png';
  }

  onSubmit() {
    if (!this.form.valid) {
      console.log('form invalid');
      return;
    }

    const body = {
      formIdentifier: this.formIdentifier,
      token: new URL(window.location.href).searchParams.get('token'),
      response: {
        ...this.model
      },
    };
    this.http.post(`api/response`, body)
      .pipe(
        map(() => {
          console.log('success');
          this.router.navigate(['/confirmation']);
          return of(null);
        }),
        catchError(() => {
          console.log('error');
          return of(null);
        }),
      )
      .subscribe();

    const redirectUrl = (window as any).form.formDefinition.redirectOnSubmit;

    if (redirectUrl) {
      window.location = redirectUrl;
    }
  }
}
