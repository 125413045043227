import {
  Component, OnInit,
} from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Component({
  selector: 'app-form',
  template: '<div *ngIf="formLoaded"><app-form-bootstrap [formIdentifier]="form.formIdentifier"></app-form-bootstrap></div>',
})
export class FormComponent implements OnInit {
  formLoaded = false;
  form = {} as any;

  constructor(
    private http: HttpClient,
  ) {
  }

  ngOnInit(): void {
    const token = new URL(window.location.href).searchParams.get('token');

    if (!window['form']) {
      console.log('form not set in window by environment - falling back to localhost for local testing');
      const formIdentifier = new URL(window.location.href).searchParams.get('formIdentifier');
      this.http.get(`http://localhost:8080/forms/identifier=${formIdentifier}`, {
        headers: new HttpHeaders({
          'X-Forms-Authorization': token
        })
      }).subscribe((f: any) => {
        this.form = f;
        window['form'] = f;
        this.formLoaded = true;
      });
    } else {
      this.form = window['form'];
      this.formLoaded = true;
    }
  }
}
