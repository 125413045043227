<div class="input-group">
  <input
    type="hidden"
    #valueInput
    [formControl]="formControl"
    [formlyAttributes]="field"
    value="test"
  />
  <input
    class="form-control"
    #placesInput
    [class.is-invalid]="showError">
</div>
