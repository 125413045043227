<!--<div>-->
<!--<button (click)="loadComponent1()">Material</button>-->
<!--<button (click)="loadComponent2()">Bootstrap</button>-->
<!--</div>-->
<div>
<!--  <button (click)="loadForm('delirium')">Delirium</button>-->
<!--  <button (click)="loadForm('telco')">Report Issue</button>-->
<!--  <button (click)="loadForm('water')">Report Water Issue</button>-->
<!--  <button (click)="loadForm('covid')">COVID-19</button>-->
<!--  <button (click)="loadForm(form.formIdentifier)" *ngFor="let form of forms">{{form.name}}</button>-->
</div>

<router-outlet></router-outlet>
