<div *ngIf="formIdentifier" style="padding: 16px">
  <div class="header">
    <div class="image-container">
      <img class="header-img" [src]="url" *ngIf="url"/>
    </div>
    <div class="header-title" *ngIf="title">{{title}}</div>
    <div class="header-description" *ngIf="description">{{description}}</div>
  </div>
  <form [formGroup]="form" (ngSubmit)="onSubmit()">
    <formly-form [form]="form" [fields]="fields" [model]="model" [options]="options"></formly-form>
    <div class="button-row">
      <button type="submit" class="btn btn-dark">Submit</button>
<!--      <button (click)="options.resetModel()" class="btn btn-light" fill="clear">Reset</button>-->
    </div>
  </form>
</div>
