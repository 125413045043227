import { FormlyFieldConfig } from '@ngx-formly/core';
import {Observable, of} from 'rxjs';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {map} from 'rxjs/operators';

const validation = {
  messages: {
    required: 'This field is required',
    minlength: (err, field) => `Must have at least ${field.templateOptions.minLength} characters`,
    maxLength: (err, field) => `Must be less than ${field.templateOptions.maxLength} characters`,
    min: (err, field) => `Must be greater than ${field.templateOptions.min}`,
    max: (err, field) => `Must be less than ${field.templateOptions.max}`,
  }
};

export const deliriumForm: FormlyFieldConfig[] = [
  {
    key: 'triage',
    type: 'select',
    // wrappers: ['question', 'form-field'],
    templateOptions: {
      label: 'Triage',
      description: 'Your patient\'s triage category',
      placeholder: 'Select Category',
      required: true,
      options: [
        { value: 1, label: 'Category 1'},
        { value: 2, label: 'Category 2'},
        { value: 3, label: 'Category 3'},
        { value: 4, label: 'Category 4'},
        { value: 5, label: 'Category 5'},
      ]
    }
  },
  {
    key: 'fourAt',
    type: 'input',
    // wrappers: ['question', 'form-field'],
    templateOptions: {
      type: 'number',
      label: '4AT',
      questionDescription: 'What is your patient\'s 4AT if applicable',
      placeholder: '4AT',
    }
  },
  {
    key: 'age',
    type: 'input',
    // wrappers: ['question', 'form-field'],
    templateOptions: {
      type: 'number',
      label: 'Age',
      questionDescription: 'How old is your patient?',
      required: true,
      min: 0,
      max: 120,
      placeholder: 'Patient\'s age'
    }
  },
  {
    key: 'depressive',
    type: 'radio',
    // wrappers: ['question', 'form-field'],
    templateOptions: {
      label: 'Depressive Symptoms',
      questionDescription: 'Does your patient have depressive symptoms?',
      required: true,
      options: [
        { value: 'yes', label: 'Yes' },
        { value: 'no', label: 'No' },
        { value: 'unsure', label: 'Unsure' },
      ],
    }
  },
  {
    key: 'cognitive',
    type: 'radio',
    // wrappers: ['question', 'form-field'],
    templateOptions: {
      label: 'Cognitive Impairment',
      questionDescription: 'Does your patient have cognitive impairment?',
      required: true,
      options: [
        { value: 'yes', label: 'Yes' },
        { value: 'no', label: 'No' },
      ],
    }
  },
  {
    key: 'delirium',
    type: 'radio',
    // wrappers: ['question', 'form-field'],
    templateOptions: {
      label: 'Delirium History',
      questionDescription: 'Does your patient have a history of delirium?',
      required: true,
      options: [
        { value: 'yes', label: 'Yes' },
        { value: 'no', label: 'No' },
        { value: 'unsure', label: 'Unsure' },
      ],
    }
  },
  {
    key: 'operative',
    type: 'radio',
    // wrappers: ['question', 'form-field'],
    templateOptions: {
      label: 'Pre or Post Operative',
      questionDescription: 'Is the patient post-operative or are they scheduled for surgery?',
      required: true,
      options: [
        { value: 'post', label: 'Post Operative' },
        { value: 'pre', label: 'Scheduled for surgery' },
        { value: 'none', label: 'None' },
      ],
    }
  },
  {
    key: 'assistance',
    type: 'radio',
    // wrappers: ['question', 'form-field'],
    templateOptions: {
      label: 'Personal Assistance',
      questionDescription: 'Does the patient need assistance with personal activities of daily living?',
      required: true,
      options: [
        { value: 'yes', label: 'Yes' },
        { value: 'no', label: 'No' },
      ],
    }
  },
  {
    key: 'alcohol',
    type: 'radio',
    // wrappers: ['question', 'form-field'],
    templateOptions: {
      label: 'Alcohol Consumption',
      questionDescription: 'How many standard drinks does the patient consume, on average, per week?',
      secondaryDescription: 'Note: One glass of wine represents 1.5 standard drinks',
      required: true,
      options: [
        { value: '0', label: '0' },
        { value: '1-5', label: '1-5' },
        { value: '6-10', label: '6-10' },
        { value: '11+', label: '11+' },
      ],
    }
  },
  {
    key: 'vision',
    type: 'radio',
    // wrappers: ['question', 'form-field'],
    templateOptions: {
      label: 'Vision Impairment',
      questionDescription: 'Does the patient have vision impairment?',
      required: true,
      options: [
        { value: 'yes', label: 'Yes' },
        { value: 'no', label: 'No' },
        { value: 'unsure', label: 'Unsure' },
      ],
    }
  },
  {
    key: 'hearing',
    type: 'radio',
    // wrappers: ['question', 'form-field'],
    templateOptions: {
      label: 'Hearing Impairment',
      questionDescription: 'Does the patient have hearing impairment?',
      required: true,
      options: [
        { value: 'yes', label: 'Yes' },
        { value: 'no', label: 'No' },
        { value: 'unsure', label: 'Unsure' },
      ],
    }
  },
  {
    key: 'medication',
    type: 'multicheckbox',
    // wrappers: ['question', 'form-field'],
    templateOptions: {
      label: 'Medication Use',
      questionDescription: 'Are any of these medications being used?',
      options: [
        {
          key: 'anticholinergics',
          value: 'Anticholinergics'
        },
        {
          key: 'antipsychotics',
          value: 'Antipsychotics'
        },
        {
          key: 'antidepressants',
          value: 'Antidepressants'
        },
        {
          key: 'sedatives',
          value: 'Sedatives'
        },
        {
          key: 'opiates',
          value: 'Opiates'
        },
      ],
    }
  },
  {
    key: 'urea',
    type: 'input',
    // wrappers: ['question', 'form-field'],
    templateOptions: {
      type: 'number',
      label: 'Urea (mmol/L)',
      // label: 'mmol/L',
      questionDescription: 'Enter the most recent amount of urea for this patient',
      required: true,
    }
  },
  {
    key: 'creatinine',
    type: 'input',
    // wrappers: ['question', 'form-field'],
    templateOptions: {
      type: 'number',
      label: 'Creatinine (μmol/L)',
      // label: 'μmol/L',
      questionDescription: 'Enter the most recent amount of creatinine for this patient',
      required: true,
    }
  },
].map((f: FormlyFieldConfig) => ({...f, validation}));

export const otherForm: FormlyFieldConfig[] = [
  {
    key: 'serviceType',
    type: 'select',
    // wrappers: ['question', 'form-field'],
    templateOptions: {
      label: 'Service Type',
      description: 'What\'s your service?',
      placeholder: 'Select service type',
      required: true,
      options: [
        { value: 0, label: 'Mobile'},
        { value: 1, label: 'Home Broadband (NBN)'},
        { value: 2, label: 'Home Broadband (Cable)'},
        { value: 3, label: 'Mobile Broadband (4G)'},
        { value: 4, label: 'Mobile Broadband (5G)'},
      ]
    }
  },
  {
    key: 'issue',
    type: 'select',
    templateOptions: {
      label: 'Issue',
      description: 'What\'s your issue?',
      placeholder: 'Select Category',
      required: true,
      options: [
        { value: 1, label: 'Internet is not working'},
        { value: 2, label: 'Connectivity is intermittent'},
        { value: 3, label: 'Internet is slow'},
        { value: 4, label: 'Other'},
      ]
    }
  },
  {
    key: 'name',
    type: 'input',
    // wrappers: ['question', 'form-field'],
    templateOptions: {
      label: 'Name',
      questionDescription: 'What is your name?',
      required: true,
      placeholder: 'Enter name',
    }
  },
  {
    key: 'address',
    type: 'input',
    // wrappers: ['question', 'form-field'],
    templateOptions: {
      label: 'Address',
      questionDescription: 'What is your address?',
      required: true,
      placeholder: 'Enter Address'
    }
  },
  {
    key: 'Datepicker',
    type: 'datepicker',
    templateOptions: {
      label: 'Date Noticed',
      placeholder: 'Choose Date',
      description: 'The date you first noticed the issue',
      required: true,
    },
  },
].map((f: FormlyFieldConfig) => ({...f, validation}));

export const waterForm: FormlyFieldConfig[] = [
  {
    key: 'name',
    type: 'input',
    // wrappers: ['question', 'form-field'],
    templateOptions: {
      label: 'Name',
      required: true,
      placeholder: 'Your Name',
      addonLeft: {
        class: 'fa fa-user',
      },
    }
  },
  {
    key: 'mobile',
    type: 'input',
    // wrappers: ['question', 'form-field'],
    templateOptions: {
      label: 'Phone Number',
      description: 'Psst. You\'ll get a fancy SMS confirmation!',
      placeholder: 'Your Mobile Number',
      addonLeft: {
        class: 'fa fa-phone',
      },
    }
  },
  {
    key: 'email',
    type: 'input',
    // wrappers: ['question', 'form-field'],
    templateOptions: {
      label: 'Email',
      placeholder: 'Your Email Address',
      addonLeft: {
        class: 'fa fa-envelope',
      },
    }
  },
  {
    key: 'address',
    type: 'input',
    // wrappers: ['question', 'form-field'],
    templateOptions: {
      label: 'Address',
      placeholder: 'Suburb or Address',
      addonLeft: {
        class: 'fa fa-map',
      },
    }
  },
  {
    key: 'issue',
    type: 'select',
    templateOptions: {
      label: 'What type of issue is this',
      // placeholder: 'Your issue',
      required: true,
      options: [
        { value: 1, label: 'Broken Pipe'},
        { value: 4, label: 'Other'},
      ]
    }
  },
  {
    key: 'description',
    type: 'textarea',
    // wrappers: ['question', 'form-field'],
    templateOptions: {
      label: 'Description',
      placeholder: 'Your Description',
    }
  },
  // {
  //   key: 'address',
  //   type: 'input',
  //   // wrappers: ['question', 'form-field'],
  //   templateOptions: {
  //     label: 'Address',
  //     questionDescription: 'What is your address?',
  //     required: true,
  //     placeholder: 'Enter Address'
  //   }
  // },
  // {
  //   key: 'Datepicker',
  //   type: 'datepicker',
  //   templateOptions: {
  //     label: 'Date Noticed',
  //     placeholder: 'Choose Date',
  //     description: 'The date you first noticed the issue',
  //     required: true,
  //   },
  // },
].map((f: FormlyFieldConfig) => ({...f, validation}));

export const covidForm: FormlyFieldConfig[] = [
  {
    key: 'age',
    type: 'input',
    // wrappers: ['question', 'form-field'],
    templateOptions: {
      type: 'number',
      label: 'Age',
      questionDescription: 'How old is your patient?',
      min: 0,
      max: 120,
      placeholder: 'Your age'
    }
  },
  {
    key: 'travel',
    type: 'radio',
    templateOptions: {
      label: 'Overseas Travel',
      description: 'Have you recently travelled overseas?',
      options: [
        { value: 'yes', label: 'Yes' },
        { value: 'no', label: 'No' },
      ],
    }
  },
  {
    key: 'contact',
    type: 'radio',
    templateOptions: {
      label: 'Contact with other travellers',
      description: 'Have you been in contact with someone who has travelled overseas?',
      options: [
        { value: 'yes', label: 'Yes' },
        { value: 'no', label: 'No' },
      ],
    }
  },
  {
    key: 'symptoms',
    type: 'multicheckbox',
    // wrappers: ['question', 'form-field'],
    templateOptions: {
      label: 'Symptoms',
      questionDescription: 'Which of these symptoms are you experiencing?',
      options: [
        {
          key: 'fever',
          value: 'Fever'
        },
        {
          key: 'cough',
          value: 'Cough'
        },
        {
          key: 'throat',
          value: 'Sore Throat'
        },
        {
          key: 'breath',
          value: 'Shortness of Breath'
        },
        {
          key: 'fatigue',
          value: 'Fatigue'
        },
        {
          key: 'aches',
          value: 'Aches & Pains'
        },
        {
          key: 'headache',
          value: 'Headaches'
        },
        {
          key: 'nose',
          value: 'Runny or Stuffy Nose'
        },
        {
          key: 'diarrhea',
          value: 'Diarrhea'
        },
        {
          key: 'sneezing',
          value: 'Sneezing'
        },
      ],
    }
  },
].map((f: FormlyFieldConfig) => ({...f, validation}));

export function getForm() {
  const response = (window as any).form;
  return {
    title: response.name,
    description: response.description,
    form: response.formDefinition.fields.map(f => {
      const field =  {
        key: f.key,
        type: f.type,
        templateOptions: {
          ...f,
        }
      };

      if (field.type === 'multicheckbox') {
        field.templateOptions.type = 'array';
      }

      return field;
    })
  };
}
