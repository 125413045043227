import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { ConfirmationComponent } from './form-bootstrap/confirmation/confirmation.component';
import { FormComponent } from './form/form.component';
import { FormBootstrapComponent } from './form-bootstrap/form-bootstrap.component';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormlyModule } from '@ngx-formly/core';
import { BootstrapDatepickerComponent } from './form-bootstrap/custom/bootstrap-datepicker.component';
import { BootstrapAddressComponent } from './form-bootstrap/custom/bootstrap-address/bootstrap-address.component';
import { FormlyBootstrapModule } from '@ngx-formly/bootstrap';

@NgModule({
  declarations: [
    AppComponent,
    ConfirmationComponent,
    FormComponent,
    FormBootstrapComponent,
    BootstrapDatepickerComponent,
    BootstrapAddressComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    FormlyModule.forRoot({
      types: [{
        name: 'datepicker',
        component: BootstrapDatepickerComponent,
        wrappers: ['form-field'],
        defaultOptions: {
          templateOptions: {
            datepickerOptions: {}
          }
        }
      }, {
        name: 'address',
        component: BootstrapAddressComponent,
        wrappers: ['form-field'],
        defaultOptions: {
          templateOptions: {
          }
        }
      }]
    }),
    FormlyBootstrapModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
